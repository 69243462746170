/* Ensure no horizontal scroll */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Disable horizontal scrolling */
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url('../../assets/AI-image.jpg') center/cover no-repeat;
  color: white;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden; /* Disable horizontal scroll within the page container */
}

.content-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 80px;
  position: relative;
}

.chat-container {
  text-align: left;
  max-width: 600px;
  width: 100%;
  background: rgba(255, 255, 255, 0.15); /* Semi-transparent background */
  padding: 25px;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft, elevated shadow */
  backdrop-filter: blur(10px); /* Blur effect for a frosted glass look */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Subtle border for the glass effect */
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  text-align: center;
}

.input-section {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}

.input-wrapper {
  position: relative;
  flex-grow: 1;
}

.user-input {
  width: 92%;
  padding: 12px 20px; /* Adjust padding */
  border-radius: 50px;
  border: none;
  font-size: 1.1rem;
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.user-input::placeholder {
  color: #aaa; /* Placeholder color */
}

.waveform {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  height: 40px; /* Match the height of the input field */
  width: 100%;
}

.bar {
  width: 4px;
  height: 100%;
  background-color: #2196F3;
  animation: wave 1.2s infinite ease-in-out;
}

.bar:nth-child(2) {
  animation-delay: -1.1s;
}
.bar:nth-child(3) {
  animation-delay: -1s;
}
.bar:nth-child(4) {
  animation-delay: -0.9s;
}
.bar:nth-child(5) {
  animation-delay: -0.8s;
}
.bar:nth-child(6) {
  animation-delay: -0.7s;
}
.bar:nth-child(7) {
  animation-delay: -0.6s;
}

@keyframes wave {
  0% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
  100% {
    transform: scaleY(1);
  }
}

.speak-button {
  background-color: #2196F3;
  border: none;
  color: white;
  font-size: 28px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  /* Prevent text selection */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */

  /* Prevent touch callouts */
  -webkit-touch-callout: none; /* iOS Safari */
}




.send-button {
  background-color: #1E90FF; 
  color: white;
  padding: 12px;
  border-radius: 50%;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  margin-left: 12px;
  margin-bottom: 20px;
  width: 45px; /* Width to create a circle */
  height: 45px; /* Height to create a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.send-button:hover {
  background-color: #1C86EE; /* Slightly darker blue on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Arrow icon inside the send button */
.send-button::before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid white;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  transform: translateX(1px); /* Slight adjustment to center the arrow */
}

.conversation-section {
  max-height: 150px; /* Set the maximum height to 150px */
  overflow-y: auto; /* Add a vertical scrollbar if content exceeds max height */
  padding: 15px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
}

.conversation-section div {
  margin-bottom: 10px;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.conversation-section strong {
  color: #ff9800;
}

.ai-mode-dropdown {
  position: absolute;
  top: 80px;  /* Keep the dropdown down a bit */
  left: 20px; /* Move it to the left of the page */
  padding: 8px 12px; /* Adjust the padding for better appearance */
  border-radius: 20px; /* Make the corners slightly more rounded */
  border: none;
  font-size: 0.9rem; /* Slightly reduce the font size */
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  z-index: 1000; /* Ensure it stays on top of other elements */
  width: 150px; /* Decrease the width of the dropdown */
}


.intro-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: #333;
}

.understand-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.understand-button:hover {
  background-color: #45a049;
}









.feedback-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darken the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  animation: fadeInModal 0.3s ease-in-out; /* Smooth fade-in animation */
}

.feedback-modal .modal-content {
  background: rgba(255, 255, 255, 0.85); /* Frosted glass effect */
  padding: 30px;
  border-radius: 15px; /* Rounded corners */
  max-width: 600px;
  max-height: 80vh;
  width: 90%;
  text-align: left; /* Align text to the left for better readability */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Soft shadow for elevation */
  backdrop-filter: blur(15px); /* Blur effect for frosted glass */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Light border */
  overflow-y: auto; /* Modal will scroll if content exceeds height */
}

/* Remove overflow from individual sections */
.feedback-section {
  max-height: none;
  overflow-y: visible;
}


.feedback-section h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  color: #333;
}

.feedback-section p, .feedback-section ul {
  font-size: 1rem;
  color: #666;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
}

.feedback-section ul {
  list-style: none;
  padding-left: 0;
}

.feedback-section ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.feedback-section ul li::before {
  content: '•';
  color: #4CAF50;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-right: 0.5em;
}

/* Animation for fade-in */
@keyframes fadeInModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

h3 {
  font-size: 1.4rem;
  font-weight: bold;
  color: #ff9800;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.feedback-section p,
.feedback-section ul {
  font-size: 1rem;
  color: #333;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
}

/* Feedback icons and headers */
.feedback-section h3::before {
  content: '⚡'; /* Add icons to the section headers */
  margin-right: 10px;
}

.feedback-section h3.feedback-summary::before {
  content: '📝'; /* Use a different icon for summary */
}

.feedback-section h3.feedback-strengths::before {
  content: '✅'; /* Icon for strengths */
}

.feedback-section h3.feedback-improvement::before {
  content: '⚠️'; /* Icon for areas of improvement */
}

.feedback-section h3.feedback-recommendations::before {
  content: '📈'; /* Icon for recommendations */
}

/* Feedback strengths and improvement areas */
.feedback-section ul {
  list-style: none;
  padding-left: 0;
}

.feedback-section ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.feedback-section ul li::before {
  content: '•';
  color: #4CAF50; /* Green dot for strengths */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-right: 0.5em;
}

.feedback-section.feedback-improvement ul li::before {
  color: #ff9800; /* Orange dot for areas of improvement */
}

/* Close button styling */
.close-button {
  background-color: #1E90FF;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.close-button:hover {
  background-color: #1C86EE;
  transform: translateY(-3px);
}

.close-button {
  background-color: #1E90FF;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.close-button:hover {
  background-color: #1C86EE;
  transform: translateY(-3px);
}











/* Smooth fade-in effect for the modal */
@keyframes fadeInModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Smooth slide-up effect for the modal */
@keyframes slideUpModal {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}



@media (max-width: 768px) {
  .user-input {
    width: 90%; 
    font-size: 1rem;
    padding: 15px 15px;
  }
}

@media (max-width: 480px) {
  .user-input {
    width: 90%; 
    font-size: 0.9rem;
    padding: 15px 15px;
  }

  .chat-container {
    width: 128%;
    margin-bottom: 10px; /* Add some margin from the bottom */
  }

  .content-wrap {
    padding-bottom: 20px; /* Add some padding to the bottom */
  }
  .feedback-modal .modal-content{
    width: 80%; 
  }
}