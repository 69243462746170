/* Center the video container and make sure it is responsive */
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 830px; /* Match the max-width of the conversation container */
  margin: 0 auto;
  overflow: hidden;
  border-radius: 15px;
}

.video-container video {
  width: 100%; 
  height: 350px; /* Decrease the height of the video */
  border-radius: 15px;
  display: block;
  object-fit: cover;
  pointer-events: none; /* Ensure controls are hidden on mobile */
}

/* Additional styles to ensure controls are hidden on mobile devices */
.video-container video::-webkit-media-controls {
  display: none;
}

.video-container:hover video::-webkit-media-controls {
  display: none;
}

video:focus {
  outline: none;
}

/* Custom scrollbar for the conversation container */
.conversation-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  max-width: 800px;
  width: 100%;
  max-height: 400px; /* Set a fixed height for the container */
  overflow-y: auto;
  transition: box-shadow 0.5s ease;
}

.conversation-container::-webkit-scrollbar {
  width: 8px;
}

.conversation-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.conversation-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.conversation-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 20px;
}

.conversation-container:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

/* Adjust the message block styles to fit inside the shorter container */
.message-block-left,
.message-block-right {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  flex-shrink: 0;
}

.message-block-left {
  flex-direction: row;
}

.message-block-right {
  flex-direction: row-reverse;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 10px;
}

/* Chat bubble styles */
.chat-bubble {
  padding: 12px 18px;
  border-radius: 25px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  word-wrap: break-word;
  max-width: 60%;
  position: relative;
  transition: background 0.5s ease, transform 0.5s ease;
}

.chat-bubble:hover {
  transform: scale(1.02);
}

.aiden-bubble {
  background: linear-gradient(135deg, #3B71CA, #6a94e0);
  color: white;
  box-shadow: 0 6px 15px rgba(59, 113, 202, 0.4);
}

.kaylee-bubble {
  background: linear-gradient(135deg, #ff6b6b, #ff9494);
  color: white;
  box-shadow: 0 6px 15px rgba(255, 107, 107, 0.4);
}

.bubble-text {
  margin: 0;
  font-size: 1.6rem;
}

.bubble-text span {
  display: inline-block;
  position: relative;
  overflow: hidden;
  color: inherit;
  transition: color 0.3s ease;
  margin-right: 0.38rem;
  padding: 2px 0;
  line-height: 1.6rem;
}

.bubble-text span.active-word {
  background-color: #004D40;
  color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 5px;
  padding: 2px 0;
  line-height: 1.6rem;
}

/* Hover effect for desktop */
@media (min-width: 769px) {
  .bubble-text span:hover {
    background-color: #262626;
    color: #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 3px;
    cursor: pointer;
  }
}

/* Click effect for mobile */
@media (max-width: 768px) {
  .bubble-text span:active {
    background-color: #262626;
    color: #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 3px;
  }
  
  .chat-bubble {
    max-width: 70%; /* Increase the width of the chat bubble */
  }

  .avatar {
    width: 45px;
    height: 45px;
    margin: 0 5px; /* Adjust avatar positioning */
  }
}

/* Wrapper and main content styles */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(135deg, #fdd4b7, #fceabb);
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.left-column {
  text-align: center;
}

/* Title styles */
.daily-talk-title {
  font-family: 'Pacifico', cursive;
  font-size: 4rem;
  color: #333;
  margin-bottom: 20px;
  margin-top: 80px; 
}

/* Button container and styles */
.start-conversation-container,
.repeat-next-conversation-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.start-conversation-button {
  background: linear-gradient(135deg, #ff5e78, #ff4568);
  border: none;
  border-radius: 20px;
  padding: 12px 24px;
  font-size: 1.3rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease;
  margin: 0 10px;
  box-shadow: 0 4px 15px rgba(255, 94, 120, 0.3);
}

.start-conversation-button:hover {
  background: linear-gradient(135deg, #ff4b7a, #ff3455);
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(255, 94, 120, 0.4);
}

/* Next button with blue color */
.next-conversation-button {
  background: linear-gradient(135deg, #4A86C5, #357ABD); /* Blue gradient */
  border: none;
  border-radius: 20px;
  padding: 12px 24px;
  font-size: 1.3rem;
  color: white;
  cursor: pointer;
  transition: background-color 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease;
  margin: 0 10px;
  box-shadow: 0 4px 15px rgba(58, 117, 179, 0.3);
}

.next-conversation-button:hover {
  background: linear-gradient(135deg, #3A75B3, #3168A7); /* Darker blue on hover */
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(58, 117, 179, 0.4);
}

/* Icon button styling */
.icon-button {
  border: none;
  border-radius: 50%;
  padding: 15px; 
  margin: 0 23px; 
  cursor: pointer;
  transition: background 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease;
  box-shadow: 0 4px 15px rgba(255, 94, 120, 0.3);
  position: relative;
  overflow: hidden;
}

.icon-button.repeat-button {
  background: linear-gradient(135deg, #ff5e78, #ff4568); /* Match Start Reading button */
}

.icon-button.repeat-button:hover {
  background: linear-gradient(135deg, #ff4b7a, #ff3455); /* Hover effect matching Start Reading button */
}

.icon-button.next-icon-button {
  background: linear-gradient(135deg, #4A86C5, #357ABD); /* Match Next button */
}

.icon-button.next-icon-button:hover {
  background: linear-gradient(135deg, #3A75B3, #3168A7); /* Hover effect matching Next button */
}

.icon-button svg {
  transition: fill 0.5s ease;
}

.icon-button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(255, 94, 120, 0.4);
}

.icon-button:hover svg {
  fill: #ffffff;
}

.icon-button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  top: 50%;
  left: 50%;
  pointer-events: none;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.icon-button:active::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  transition: 0s;
}

/* Spinner container */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

/* Spinner itself */
.spinner {
  border: 8px solid rgba(255, 255, 255, 0.2);
  border-top: 8px solid #ff5e78;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* Additional styles to ensure controls are hidden and responsiveness is maintained */
.video-container video::-webkit-media-controls {
  display: none;
}

.video-container:hover video::-webkit-media-controls {
  display: none;
}

video:focus {
  outline: none;
}

/* Wrapper and main content styles */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(135deg, #fdd4b7, #fceabb);
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.left-column {
  text-align: center;
}

/* Title styles */
.daily-talk-title {
  font-family: 'Pacifico', cursive;
  font-size: 4rem;
  color: #333;
  margin-bottom: 20px;
  margin-top: 80px; 
}

/* Icon buttons container */
.icon-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  position: relative;
  z-index: 10;
}

.circle-play-button,
.circle-next-button {
  background-color: #ff5e78;
  border: none;
  border-radius: 50%;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-next-button {
  background-color: #ffab40;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
  margin-top: auto;
}

@media (max-width: 768px) {
  .main-content {
    justify-content: center; 
    padding-top: 0; 
    padding-bottom: 40px; 
  }

  .conversation-container {
    margin-top: 0; 
    width: 90%; 
    max-height: 300px; 
  }

  .video-container {
    width: 90%;
  }

  .bubble-text {
    font-size: 1.4rem;
  }

  .avatar {
    width: 45px;
    height: 45px;
  }

  .daily-talk-title {
    font-size: 3.2rem;
    margin-top: 0px;
  }
  
  .bubble-text span {
    margin-right: 0.24rem;
  }

  .video-container {
    width: 100%;
    max-width: 830px; 
  }
  
  .video-container video {
    width: 100%; 
    height: 200px; 
    margin-bottom: 20px;
    pointer-events: none; /* Ensure controls are hidden on mobile */
  }
}

@media (min-width: 1024px) {
  .main-content {
    padding-top: 180px;
  }

  .circle-play-button,
  .circle-next-button {
    width: 72px; 
    height: 72px; 
    padding: 20px; 
  }

  .video-container video {
    height: 360px; 
  }
}
