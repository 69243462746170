.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(135deg, #fdd4b7, #fceabb);
  width: 100%; /* Ensure wrapper takes full width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center;
  padding: 40px;
  width: 100%; /* Ensure main-content takes full width */
  margin-top: 60px; /* Add margin to prevent content from being cut off by the header */
}

.left-column {
  text-align: center;
}

.daily-talk-title {
  font-family: 'Pacifico', cursive;
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
}

.conversation-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  max-width: 600px;
  width: 100%; /* Ensure container takes full width */
  overflow-y: auto; /* Ensure the conversation is scrollable */
}

.message-block-left,
.message-block-right {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.message-block-left {
  flex-direction: row;
}

.message-block-right {
  flex-direction: row-reverse;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
}

.chat-bubble {
  padding: 10px 15px;
  border-radius: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  word-wrap: break-word;
  max-width: 80%;
}

.aiden-bubble {
  background: linear-gradient(135deg, #3B71CA, #6a94e0);
  color: white;
  box-shadow: 0 4px 10px rgba(59, 113, 202, 0.4);
}

.kaylee-bubble {
  background: linear-gradient(135deg, #ff6b6b, #ff9494);
  color: white;
  box-shadow: 0 4px 10px rgba(255, 107, 107, 0.4);
}

.bubble-text {
  margin: 0;
}

.active-word {
  background-color: #ffeaa7;
  padding: 2px 4px;
  border-radius: 4px;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

.icon-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%; /* Make sure the container takes the full width */
}

.circle-play-button,
.circle-next-button {
  background-color: #ff5e78;
  border: none;
  border-radius: 50%;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px; /* Set width for better sizing */
  height: 60px; /* Set height for better sizing */
}

.circle-next-button {
  background-color: #ffab40;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;
  margin-top: auto;
}

@media (max-width: 768px) {
  .main-content {
    justify-content: center;
    padding-top: 0;
    padding-bottom: 40px;
    width: 90%;
    margin-top: 80px; 
    transform: translateX(-4%);    
  }

  .conversation-container {
    margin-top: 0;
    width: 100%; 
    max-width: 100%; 
  }

  .icon-buttons {
    flex-direction: row; 
    justify-content: space-evenly; 
  }

  .circle-play-button,
  .circle-next-button {
    width: 68px; 
    height: 68px; 
  }
}


@media (min-width: 1024px) {
  .main-content {
    justify-content: center; /* Ensure centering on larger screens */
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: -180px;
  }
}
