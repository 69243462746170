/* General modal overlay */
.modal-overlay-gs {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Darker semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    backdrop-filter: blur(8px); /* Stronger blur effect to focus on the modal */
    transition: opacity 0.3s ease; /* Smooth fade-in effect */
}

/* Modal container */
.modal-content-gs {
    background: linear-gradient(135deg, #f6f7fb, #e1e3ec); /* Subtle gradient background */
    padding: 40px; /* Increase padding for a more substantial feel */
    border-radius: 15px; /* More rounded corners */
    width: 90%;
    max-width: 600px; /* Wider modal for more content */
    text-align: center;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3); /* Deeper shadow for more depth */
    border: 1px solid #e0e0e0; /* Thin border to outline the modal */
    transform: translateY(-20px); /* Initial slight move-up */
    animation: slide-down 0.3s ease forwards; /* Slide down animation */
}

@keyframes slide-down {
    to {
        transform: translateY(0);
    }
}

/* Modal title */
.modal-content-gs h2 {
    font-size: 2.6em;
    margin-bottom: 40px;
    margin-top: -5px;
    padding-bottom: 5px;
    font-family: 'Playfair Display', serif;
    color: #3B71CA;
    text-decoration: underline;
}

/* Buttons container */
.modal-buttons {
    display: flex;
    flex-direction: column;
    gap: 18px; /* Increase gap for better spacing */
    margin-top: 20px;
}

/* General game button styles */
.game-button {
    padding: 14px 22px; /* Increase padding for a more comfortable feel */
    font-size: 18px;
    border: none;
    border-radius: 10px; /* More rounded corners */
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease; /* Smooth transitions */
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Soft shadow for buttons */
}

/* Casual Talk button styles */
.game-button.friendly-chat-button {
    background-color: #FF9800; /* A warm, inviting orange color */
}

.game-button.friendly-chat-button:hover {
    background-color: #F57C00; /* A darker, richer orange on hover */
    box-shadow: 0 8px 20px rgba(255, 152, 0, 0.5); /* Subtle glow on hover */
    transform: translateY(-3px); /* Slight lift on hover */
}


/* Specific button styles */
.game-button:first-child {
    background-color: #4CAF50;
}

.game-button:first-child:hover {
    background-color: #45a049;
    box-shadow: 0 8px 20px rgba(76, 175, 80, 0.5); /* Subtle glow on hover */
    transform: translateY(-3px); /* Slight lift on hover */
}

/* Synonym Finder button styles */
.synonym-finder-button {
    background-color: #007bff; /* Blue color */
}

.synonym-finder-button:hover {
    background-color: #0056b3;
    box-shadow: 0 8px 20px rgba(0, 123, 255, 0.5); /* Subtle glow on hover */
    transform: translateY(-3px); /* Slight lift on hover */
}

/* Vocabulary Card button styles */
.vocabulary-card-button {
    background-color: #FF5722; /* A distinct orange-red color */
}

.vocabulary-card-button:hover {
    background-color: #E64A19;
    box-shadow: 0 8px 20px rgba(255, 87, 34, 0.5); /* Subtle glow on hover */
    transform: translateY(-3px); /* Slight lift on hover */
}

/* Cancel button styles */
.cancel-button {
    margin-top: 35px;
    padding: 12px 25px;
    font-size: 16px;
    border: none;
    border-radius: 10px; /* More rounded corners */
    background-color: #ccc;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.cancel-button:hover {
    background-color: #999;
    transform: translateY(-2px); /* Slight lift on hover */
}
/* Daily Talk button styles */
.daily-talk-button {
    background-color: #9C27B0; /* A vibrant purple color */
}

.daily-talk-button:hover {
    background-color: #7B1FA2; /* A darker shade of purple on hover */
    box-shadow: 0 8px 20px rgba(156, 39, 176, 0.5); /* Subtle glow on hover */
    transform: translateY(-3px); /* Slight lift on hover */
}


/* Responsive adjustments */
@media (max-width: 600px) {
    .modal-content-gs {
        padding: 25px;
        max-width: 80%;
    }
    
    .modal-content-gs h2 {
        font-size: 2em;
    }

    .game-button {
        padding: 12px 18px;
        font-size: 16px;
    }

    .cancel-button {
        padding: 10px 18px;
        font-size: 14px;
    }
}
