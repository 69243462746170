.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.topic-selection-vc h2 {
  font-size: 2.4em;
  font-family: 'Playfair Display', serif;
  color: #3B71CA;
  text-align: center;
  text-decoration: underline; 
  margin-top: 0px;
  padding-bottom: 15px;
}

.modal-content-expanded {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  max-height: 80vh; /* Set max-height for the modal */
  overflow-y: auto;  /* Enable vertical scrolling */
  text-align: center;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.topic-selection {
  margin: 20px 0;
}

.topic-selection h2 {
  font-size: 2em;
  font-family: 'Playfair Display', serif;
  color: #3B71CA;
  text-align: center;
}

.topic-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create two columns */
  gap: 20px; /* Space between the buttons */
  justify-items: center;
}

.topic-button {
  width: 380px; /* Increased width */
  height: 100px; /* Decreased height */
  background-size: cover;
  background-position: center;
  border: 2px solid #3B71CA;
  border-radius: 10px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.topic-button:hover {
  transform: scale(1.05);
}


.topic-button.selected {
  border-color: yellow;
}

.modal-button {
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  background-color: #3B71CA;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #2d57a6;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .modal-content-expanded {
    max-width: 95%;
    padding: 20px;
  }

  .topic-buttons {
    grid-template-columns: 1fr; /* Switch to one column for smaller screens */
  }

  .topic-button {
    width: 350px;
    height: 100px;
    font-size: 24px;
  }
}
