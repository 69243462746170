/* Title Styling */
.vocabulary-card-title {
  font-family: 'Raleway', sans-serif;
  font-size: 4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: linear-gradient(90deg, #3B71CA, #66a6ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  text-shadow: 2px 4px 6px rgba(0, 72, 255, 0.5);
  transition: transform 0.3s ease;
}

/* Vocabulary Game Container */
.vocabulary-game-container {
  text-align: center;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #89f7fe 0%, #66a6ff 100%);
}

/* Card Container Styling */
.vocabulary-card-container {
  position: relative;
  width: 100%;
  max-width: 550px;
  min-height: 350px; /* Set a minimum height for the card */
  perspective: 1000px;
  margin: 0 auto;
  margin-bottom: 20px;
}

/* Card Styling */
.vocabulary-card {
  width: 100%;
  min-height: 350px; /* The card will start with this height */
  height: auto; /* Allow the card to grow if the content exceeds the min-height */
  position: relative;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.6s ease;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background-color: #3B71CA;
  z-index: 1;
}

.vocabulary-card.flipped {
  transform: rotateY(180deg);
}

/* Front and Back of the Card */
.card-front, .card-back {
  width: 100%;
  min-height: 350px; /* Set the initial minimum height */
  height: auto; /* Allow the height to adjust if content grows */
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.card-front {
  background-color: #3B71CA;
}

.card-back {
  font-size: 28px;
  color: white;
  background-color: #2C5BB5;
  transform: rotateY(180deg);
  position: relative;
}

/* Center the vocabulary word in the middle of the card */
.card-front .vocabulary-word, 
.card-back p {
  margin: 0;
  text-align: center;
  word-wrap: break-word;
}

/* Language Toggle Button Styling */
.language-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  background-color: #2C5BB5;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  position: absolute;
  top: 20px; /* Position it at the top of the card */
  right: 20px; /* Position it on the right side */
}

.toggle-option {
  width: 50%;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  transition: color 0.3s ease;
}

.toggle-option.active {
  background-color: #ffffff;
  color: #2C5BB5;
  border-radius: 20px;
}

/* Vocabulary Word */
.vocabulary-word {
  font-size: 40px;
  color: white;
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: auto;
}

/* Speaker Icon Styling */
.speaker-icon {
  position: absolute;
  top: 28px;
  right: 28px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
}

.speaker-icon:hover {
  transform: scale(1.1);
  color: #ffff66;
}

/* Star Icon Styling */
.star-icon {
  position: absolute;
  top: 12px;
  left: 25px;
  font-size: 35px;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
}

.star-icon.favorited {
  color: yellow;
}

/* Button Styling */
.next-word-button {
  padding: 12px 24px;
  font-size: 18px;
  background-color: #3B71CA;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(59, 113, 202, 0.4);
  transition: background-color 0.3s ease, transform 0.1s ease;
  z-index: 10; /* Ensure the button is above other elements */
}

.next-word-button:hover {
  background-color: #2C5BB5;
  transform: scale(1.05);
}

/* Used Words Section Styling */
.used-words-section {
  margin-top: 60px;
  width: 90%;
  max-width: 730px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-height: 400px;
  overflow-y: auto;
}

.used-words-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

.used-words-section h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2em;
  margin-bottom: 10px;
  text-align: center;
}

.tip-text {
  font-size: 1.1em;
  text-align: center;
  color: #555;
  margin-bottom: 10px;
}

.words-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  margin: 25px 0;
}

.words-column {
  flex: 1 1 calc(50% - 10px);
  max-width: calc(50% - 10px);
}

.words-column ul {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.words-column ul li {
  font-size: 1.2em;
  margin-bottom: 5px;
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  text-transform: capitalize;
  text-align: left;
  transition: background 0.3s;
  cursor: pointer;
}

.words-column ul li:hover {
  background: #e1e1e1;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .vocabulary-card-container {
    max-width: 90%;
  }

  .vocabulary-card {
    min-height: 250px; /* Reduce the minimum height for smaller screens */
  }

  .card-front, .card-back {
    min-height: 250px; /* Ensure the card front and back also adapt to the smaller height */
    padding: 10px; /* Reduce padding to save space */
  }

  .card-back {
    font-size: 18px; /* Adjust font size to fit the smaller height */
  }

  .vocabulary-word {
    font-size: 30px; /* Reduce the size of the vocabulary word for smaller screens */
  }

  .vocabulary-card-title {
    font-size: 2.4rem;
    letter-spacing: 1px;
    margin-top: 80px; /* Adjust to move the card upwards */
  }

  .next-word-button {
    margin-top: -85px; /* Adjust the margin to move button upwards */
  }

  .used-words-section {
    margin-top: 10px; /* Move the Used Words section closer to the button */
    margin-bottom: 20px;
    width: 87%;
    max-width: 730px;
    padding: 10px;
    max-height: 600px;
  }

  .used-words-section h2 {
    font-family: 'Playfair Display', serif;
    font-size: 2em;
    margin-bottom: 10px;
    text-align: center;
  }
}
