.modal-overlay-dm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-dm {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  text-align: center;
  width: 700px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.modal-content-dm h2 {
  font-family: 'Playfair Display', serif;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: -10px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  text-decoration: underline;
}

.modal-content-dm h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: normal;
}

.modal-content-dm h4 {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: normal;
  color: gray;
}

/* Word Section: Aligns word and speaker icon */
.word-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}
/* Speaker button styling */
.speak-button {
  background: linear-gradient(135deg, #007BFF, #00c6ff);
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: white;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
}

.speak-button i {
  margin-left: 0;
}

/* Hover effect for speaker button */
.speak-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  background: linear-gradient(135deg, #0056b3, #0093ff);
}

.speak-button:active {
  transform: scale(0.9);
}

/* Styling for buttons (View in Vietnamese / Close) */
.modal-content-dm button {
  padding: 12px 24px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  margin-top: 20px;
}

.modal-content-dm button:hover {
  background-color: #0056b3;
}

.modal-content-dm .language-toggle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-content-dm .toggle-button {
  padding: 8px 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.word-display {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: 0;
  padding: 0;
  display: flex; /* Ensure the word behaves like a flex item */
  align-items: center; /* Center the word vertically with the speaker button */
  justify-content: center; /* Center the word horizontally */
}


.modal-content-dm .toggle-button:hover {
  background-color: #0056b3;
}

/* Media Query for Mobile Devices */
@media (max-width: 600px) {
  .modal-content-dm {
    padding: 30px;
    width: 78%;
  }

  .modal-content-dm h2 {
    font-size: 30px;
  }

  .modal-content-dm h3 {
    font-size: 26px;
  }

  .modal-content-dm h4 {
    font-size: 20px;
  }

  .modal-content-dm button {
    padding: 10px 20px;
    font-size: 16px;
  }

  .modal-content-dm .toggle-button {
    padding: 6px 12px;
    font-size: 16px;
  }
}

/* Word Section: Aligns word and speaker icon */
.dm-word-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

/* Styling for the displayed word */
.dm-word-display {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: 0;
  padding: 0;
  display: flex; /* Ensure the word behaves like a flex item */
  align-items: center; /* Center the word vertically with the speaker button */
  justify-content: center; /* Center the word horizontally */
}

/* Speaker button styling */
.dm-speak-button {
  background: linear-gradient(135deg, #007BFF, #00c6ff);
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: white;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
}

.dm-speak-button i {
  margin-left: 0;
}
