.navbar {
    background-color: #333;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-image {
    height: 60px; /* Adjust as needed */
    display: inline-block;
    padding-left: 20px;
}

.nav-links {
    display: flex;
    gap: 30px; /* Adjust the spacing between links */
    margin-left: auto; /* Push the links to the right */
    padding-right: 80px;
    position: relative; /* Positioning for dropdown */
}

.nav-link {
    color: #007bff; /* Blue color for the links */
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s;
}

.nav-link:hover {
    color: #0056b3; /* Darker blue on hover */
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    background: none;
    border: none;
    color: #007bff;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-toggle .arrow {
    margin-left: 8px;
    font-size: 12px; /* Adjust the size of the arrow */
}

/* Keep the blue button styling for More Game */
.blue-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.blue-button:hover {
    background-color: #0056b3;
}

.dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #ffffff; /* Changed to white */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-item {
    color: #007bff;
    text-decoration: none;
    padding: 12px 16px;
    transition: background-color 0.3s;
}

.dropdown-item:hover {
    background-color: #0056b3;
    color: white;
}
