.synonym-finder-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #6dd5ed, #2193b0);
  padding: 20px;
}

.synonym-finder-container {
  width: 730px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 180px; /* Increased margin-top to move the container down */
}

.synonym-finder-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

.synonym-finder-title {
  font-family: 'Playfair Display', serif;
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  font-weight: normal;
}

.synonym-finder-title::after {
  content: '';
  display: block;
  width: 50%;
  margin: 0.5em auto;
  border-bottom: 2px solid #000;
}

/* Timer container styling */
.timer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 20px;
}

.timer-svg {
  width: 80px;
  height: 80px;
}

.timer-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.timer-fg {
  fill: none;
  stroke: #3B71CA;
  stroke-width: 3.8;
  stroke-linecap: round;
  transition: stroke-dasharray 1s linear;
}

.timer-text {
  fill: #000;
  font-size: 0.7em;
  text-anchor: middle;
}

.word-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: capitalize;
}

/* Synonym options buttons styling */
.synonym-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 10px;
  font-size: 1.1em;
  padding-top: 20px;
}

.synonym-options button {
  flex: 1;
  font-size: 1.1em;
  padding: 10px 0;
  border-radius: 20px;
  border: 2px solid #3B71CA;
  background-color: #fff;
  color: #3B71CA;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  margin: 3px;
}

.synonym-options button:hover {
  background-color: #3356A8;
  color: white;
}

.synonym-options button.selected {
  background-color: #d0e8ff;
}

.synonym-options button.correct {
  background-color: #00c851;
  color: white;
  border: 2px solid #00c851;
}

.synonym-options button.incorrect {
  background-color: #ff4444;
  color: white;
  border: 2px solid #ff4444;
}

/* Next Question button styling */
.next-question-button {
  font-size: 1.1em;
  padding: 10px 10;
  border-radius: 20px;
  background-color: #3B71CA;
  color: white;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
}

.next-question-button:hover {
  background-color: #3356A8;
}

.next-question-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Feedback styling */
.synonym-feedback {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.synonym-feedback-correct {
  color: #00c851;
}

/* Game over section styling */
.game-over h3,
.game-over p {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.play-again-button {
  font-size: 1.1em;
  padding: 10px 10;
  border-radius: 20px;
  background-color: #3B71CA;
  color: white;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.play-again-button:hover {
  background-color: #3356A8;
}

/* Used words section styling */
.used-words-section-sf {
  width: 90%;
  max-width: 730px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
}

.used-words-section-sf:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}

.used-words-section-sf h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2em;
  margin-bottom: 10px;
  text-align: center;
  position: relative;
  font-weight: normal;
}

.used-words-section-sf h2::after {
  content: '';
  display: block;
  width: 50%;
  margin: 0.5em auto;
  border-bottom: 2px solid #000;
}

.words-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  margin: 25px 0;
}

.words-column {
  flex: 1 1 calc(50% - 10px);
  max-width: calc(50% - 10px);
}

.words-column ul {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.words-column ul li {
  font-size: 1.2em;
  margin-bottom: 5px;
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  text-transform: capitalize;
  text-align: left;
  transition: background 0.3s;
  cursor: pointer;
}

.words-column ul li:hover {
  background: #e1e1e1;
}

.language-toggle {
  text-align: right;
  margin-bottom: 10px;
}

.toggle-button {
  padding: 5px 10px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #3B71CA;
  color: white;
}

.toggle-button:hover {
  background-color: #3356A8;
}

.game-over-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.play-again-button,
.change-level-button {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #3B71CA;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.play-again-button:hover,
.change-level-button:hover {
  background-color: #2d57a6;
}


/* Media Query for Mobile Devices */
@media (max-width: 600px) {
  .synonym-finder-container {
    width: 90%;
    padding: 20px;
    margin-top: 20px;
  }

  .word-title {
    font-size: 1.6em;
  }

  .synonym-options {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .synonym-options button {
    flex: 0 0 45%;
    font-size: 0.9em;
    padding: 8px 0;
    margin: 3px;
  }

  .timer-container {
    margin-bottom: 30px;
  }

  .used-words-section-sf {
    width: 90%; 
    padding: 20px;
    max-height: 400px;
  }
}
