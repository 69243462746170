.modal-content-sf {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.level-selection {
  margin: 20px 0;
}

.level-selection label {
  display: block;
  margin-bottom: 10px;
  font-size: 2em; /* Same size as the heading */
  font-family: 'Playfair Display', serif; /* Same font as the heading */
  color: #333; /* Ensure the color is consistent */
  text-align: center;
}

.level-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.level-button {
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid #3B71CA;
  border-radius: 30px;
  background-color: white;
  color: #3B71CA;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.level-button:hover {
  background-color: #3B71CA;
  color: white;
}

.level-button.active {
  background-color: #3B71CA;
  color: white;
}

.modal-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #3B71CA;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-button:hover {
  background-color: #2d57a6;
}
