.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
}

h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

ul {
    list-style-type: none;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 20px;
}

ul li {
    background: #f1f1f1;
    margin: 5px 0;
    padding: 15px;
    border-radius: 5px;
    transition: background 0.3s;
    font-size: 18px;  /* Increased font size */
    text-transform: capitalize;  /* Capitalize the first letter */
}

ul li:hover {
    background: #e1e1e1;
}

.close-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 25px;
    background-color: #007bff;
    color: #fff;
    border: none;
    transition: background 0.3s, transform 0.3s;
}

.close-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
